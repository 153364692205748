var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition-group',{attrs:{"name":"fade","tag":"div","mode":"out-in"}},[_vm._l((_vm.notifications.error),function(ref){
var message = ref.message;
var key = ref.key;
return _c('div',{key:key,staticClass:"alert alert-dismissible alert-danger",attrs:{"role":"alert"}},[_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":_vm.$t('close')},on:{"click":function($event){return _vm.dismissError(message)}}},[_vm._v("×")]),_c('span',{staticClass:"content"},[_vm._v(_vm._s(message))])])}),_vm._l((_vm.notifications.info),function(ref){
var message = ref.message;
var key = ref.key;
return _c('div',{key:key,staticClass:"alert alert-dismissible alert-info",attrs:{"role":"alert"}},[_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":_vm.$t('close')},on:{"click":function($event){return _vm.dismissInfo(message)}}},[_vm._v("×")]),_c('span',{staticClass:"content"},[_vm._v(_vm._s(message))])])}),_vm._l((_vm.notifications.warning),function(ref){
var message = ref.message;
var key = ref.key;
return _c('div',{key:key,staticClass:"alert alert-dismissible alert-warning",attrs:{"role":"alert"}},[_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":_vm.$t('close')},on:{"click":function($event){return _vm.dismissWarning(message)}}},[_vm._v("×")]),_c('span',{staticClass:"content"},[_vm._v(_vm._s(message))])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }